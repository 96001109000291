<template lang='pug'>
  .awesome-profile-card.awesome-profile-container
    .profile-avatar
      img(:src='avatar()' alt='avatar')
      .profile-avatar-icons
        .profile-avatar-icon__edit(@click='handleOpenDialogProfileAvatar(true)')
        .profile-avatar-icon__delete(@click='handleOpenDialogProfileAvatar(false)' v-if='!isAvatarExists')
    .awesome-profile-card__content
      .profile-title
        span {{ userProfileDetails.login || '' }}
      .content-profile
        .profile-rating
          .content-subheader
            span Рейтинг
          .content-desc
            span {{ userProfileDetails.trust_coef + '%' }}
        .profile-reviews
          .content-subheader
            span Отзывы
          .content-desc
            span {{ totalReviews }}
      .awesome-profile-card__footer
        el-tooltip(
          v-if='profile.kyc && profile.kyc.is_blocked'
          effect='dark'
          placement='top'
        )
          img.status-error-kyc(src='/awesome-icons/warning.svg')
          div.canceled-kyc-message(slot="content")
            p Верификация личности отклонена.
            p {{ profile.kyc.verification_comment }}
            p Попробуйте пройти верификацию повторно.
        .profile-kyc-status(v-if='isKycConfirmed')
          img.icon(src='/awesome-icons/btn-success-check.svg')
          | Аккаунт верифицирован
        .profile-kyc-status(v-else-if='isKycPending')
          img.icon(src='/awesome-icons/waiting.svg')
          | На проверке
        button.awesome-btn(
          v-else
          :class='{ "awesome-btn-outline": isDisabledBtn, "profile-btn": isDisabledBtn, "default-cursor": isDisabledBtn }'
          @click='handleOpenDialog'
        )
          span Пройти верификацию
    template(v-if='visibleDialog')
      DialogProfileVerification(
        :opened='visibleDialog'
        @close='handleCloseDialog'
      )
    template(v-if='visibleDialogProfileAvatar')
      DialogProfileAvatar(
        :opened='visibleDialogProfileAvatar'
        :is-edit='isEditProfileAvatar'
        @close='handleCloseDialogProfileAvatar'
      )
</template>

<script>
import { mapGetters } from 'vuex';
import DialogProfileVerification
  from '@/components/AwesomeComponents/Account/Profile/Dialogs/DialogProfileVerification.vue';
import DialogProfileAvatar from '@/components/AwesomeComponents/Account/Profile/Dialogs/DialogProfileAvatar.vue';

export default {
  name: 'Profile',
  components: { DialogProfileAvatar, DialogProfileVerification },
  props: {
    totalReviews: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      visibleDialog: false,
      visibleDialogProfileAvatar: false,
      isEditProfileAvatar: false,
    };
  },
  computed: {
    ...mapGetters({
      'userProfileDetails': 'getDetails',
      'profile': 'getProfile',
      'avatar': 'getAvatar',
    }),
    isDisabledBtn() {
      return this.profile.is_kyc_confirmed || (this.profile.kyc && this.profile.kyc.is_pending);
    },
    isKycConfirmed() {
      return this.profile.is_kyc_confirmed;
    },
    isKycPending() {
      return Boolean(this.profile.kyc && this.profile.kyc.is_pending);
    },
    isAvatarExists() {
      return this.avatar() === '/img/default-avatar.png';
    },
  },
  methods: {
    handleOpenDialog() {
      if (this.isDisabledBtn) return;
      this.visibleDialog = true;
    },
    handleCloseDialog() {
      this.visibleDialog = false;
    },
    handleOpenDialogProfileAvatar(isEdit) {
      this.visibleDialogProfileAvatar = true;
      this.isEditProfileAvatar = isEdit;
    },
    handleCloseDialogProfileAvatar() {
      this.visibleDialogProfileAvatar = false;
      this.isEditProfileAvatar = false;
    },
  },
};
</script>

<style scoped lang='scss'>
.canceled-kyc-message {
  p {
    margin-bottom: 0.5em;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
</style>
