<template lang='pug'>
  .awesome-profile-notifications__container
    .awesome-profile-notifications__tabs
      AwesomeTabs(
        :tabs='tabs'
        :selected-tab='selectedTab'
        custom-class-nav='awesome-profile-notifications__tabs-nav'
        custom-class-nav-item='awesome-profile-notifications__tabs-nav-item'
        @changeTab='changeTab'
      )
        template(#icon)
          img.awesome-profile-notifications__tabs-nav-item-img(src='/awesome-icons/right-arrow.svg')
      .profile-notification__telegram(v-if='selectedTab === "telegram" && hasTelegramVerificationCode')
        span
          | Отправьте проверочный код <b class='profile-notification__telegram-id'>{{ profile.telegram_verification_code }}</b>
          | боту <a href='https://t.me/FinexInfoBot' class='profile-notification__telegram-bot' target='_blank' title='Telegram'>@FinexInfoBot</a> и дождитесь подтверждения
          | <br>
          | Обычно это занимает пару минут
    .awesome-profile-notifications__tabs-content
      Notifications(:notifications-channel='notificationChannelData' @togglePosition='togglePosition')
</template>

<script>
import AwesomeTabs from '@/components/AwesomeComponents/Tabs.vue';
import Notifications from '@/components/AwesomeComponents/Account/Profile/Notifications/Notifications.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'ProfileNotifications',
  components: {
    AwesomeTabs,
    Notifications,
  },
  data() {
    return {
      tabs: [
        { name: 'web', label: 'Web' },
        { name: 'telegram', label: 'Telegram' },
        { name: 'email', label: 'E-mail' },
      ],
      selectedTab: 'web',
      notificationChannels: [
        {
          label: 'Web',
          value: false,
          type: 'web',
          description: 'Уведомления приходят при личных сообщениях и обновлениях в сделке.',
          types: [
            {
              label: 'Сделки',
              type: 'deal',
              value: false,
            },
            {
              label: 'Уведомления о продаже',
              type: 'deal_sale',
              requestAlias: 'deal-sale',
              value: false,
            },
            {
              label: 'Уведомления о покупке',
              type: 'deal_buy',
              requestAlias: 'deal-buy',
              value: false,
            },
            {
              label: 'Чат в сделке',
              type: 'message',
              value: false,
            },
            {
              label: 'Претензии',
              type: 'claim',
              value: false,
            },
            {
              label: 'Автоматическое закрытие',
              type: 'pro_claim_applied',
              requestAlias: 'pro-claim-applied',
              value: false,
              onlyForPro: true,
            },
            {
              label: 'Безопасность (выводы, авторизации, смены пароля почты и т.д)',
              type: 'security',
              value: false,
            },
          ],
        },
        {
          label: 'Telegram',
          value: false,
          type: 'telegram',
          description: 'Уведомления приходят при создании новой сделки и изменении почты, а также о новых сообщениях, если вы оффлайн.',
          types: [
            {
              label: 'Сделки',
              type: 'deal',
              value: false,
            },
            {
              label: 'Уведомления о продаже',
              type: 'deal_sale',
              requestAlias: 'deal-sale',
              value: false,
            },
            {
              label: 'Уведомления о покупке',
              type: 'deal_buy',
              requestAlias: 'deal-buy',
              value: false,
            },
            {
              label: 'Чат в сделке',
              type: 'message',
              value: false,
            },
            {
              label: 'Претензии',
              type: 'claim',
              value: false,
            },
            {
              label: 'Автоматическое закрытие',
              type: 'pro_claim_applied',
              requestAlias: 'pro-claim-applied',
              value: false,
              onlyForPro: true,
            },
            {
              label: 'Безопасность (выводы, авторизации, смены пароля почты и т.д)',
              type: 'security',
              value: false,
            },
          ],
        },
        {
          label: 'E-mail',
          value: false,
          type: 'email',
          description: 'Уведомления приходят при создании чата, новой сделки и изменении почты.',
          types: [
            {
              label: 'Сделки',
              type: 'deal',
              value: false,
            },
            {
              label: 'Уведомления о продаже',
              type: 'deal_sale',
              requestAlias: 'deal-sale',
              value: false,
            },
            {
              label: 'Уведомления о покупке',
              type: 'deal_buy',
              requestAlias: 'deal-buy',
              value: false,
            },
            {
              label: 'Чат в сделке',
              type: 'message',
              value: false,
            },
            {
              label: 'Претензии',
              type: 'claim',
              value: false,
            },
            {
              label: 'Автоматическое закрытие',
              type: 'pro_claim_applied',
              requestAlias: 'pro-claim-applied',
              value: false,
              onlyForPro: true,
            },
            {
              label: 'Безопасность (выводы, авторизации, смены пароля почты и т.д.)',
              type: 'security',
              value: false,
              alwaysEnabled: true,
            },
          ],
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      'options': 'getSettings',
      'profile': 'getProfile',
      'isPro': 'getIsProUser',
    }),
    hasTelegramVerificationCode() {
      if (this.profile.hasOwnProperty('telegram_verification_code')) {
        return !!this.profile.telegram_verification_code.length;
      }
      return false;
    },
    notificationChannelData() {
      const channel = this.notificationChannels.find(notification => notification.type === this.selectedTab);
      if (this.isPro) {
        return channel;
      } else {
        return {
          ...channel,
          types: channel.types.filter(type => !type.onlyForPro),
        };
      }
    },
  },
  methods: {
    changeTab(tabName) {
      this.selectedTab = tabName;
    },
    togglePosition() {
      Object.keys(this.options).forEach(i => {
        const channel = this.notificationChannels.find(channel => channel.type === i);
        if (channel) {
          channel.value = this.options[i].notification_required;
          channel.types.forEach(type => {
            Object.keys(this.options[i]).forEach(key => {
              if (key === `${type.type}_notification`) {
                type.value = this.options[i][key];
              }
            });
          });
        }
      });
    },
  },
};
</script>

<style scoped lang='scss'>
</style>
