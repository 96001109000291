<template lang='pug'>
.awesome-profile-pro-rating(v-loading="isProRatingLoading")
  .awesome-profile-pro-rating__title
    span Рейтинг
    .awesome-tag PRO
  .awesome-profile-pro-rating__content
    .awesome-profile-pro-rating__content__left-side
      .awesome-profile-pro-rating__content__item(v-for="rating in proRatingTop3")
        .payment-banks
          .content-subheader.content-subheader_main
            span {{ rating.payment_system.title }}
          .content-desc
            span {{ rating.rating * 100 }}%
        .position
          .content-subheader
            span Позиция
          .content-desc
            span {{ rating.position }} из {{ rating.totalPositions }}
    .awesome-profile-pro-rating__content__left-side
      button.awesome-btn.awesome-btn-outline(@click="isRatingProModalOpened = true")
        span Все способы оплаты

  el-dialog.awesome-el-dialog.standard-dialog.rating-pro-modal(
    width="368px"
    :visible.sync="isRatingProModalOpened"
  )
    template(v-slot:title)
      .rating-pro-modal__title
        | Рейтинг
        .awesome-tag.awesome-tag_big PRO
    .rating-pro-modal__content
      .rating-pro-modal__list
        .rating-pro-modal__list__item(v-for="rating in proRatingSortedByPriority")
          .rating-pro-modal__block-rating
            .rating-pro-modal__rating-title {{ rating.payment_system.title }}
            .rating-pro-modal__value {{ rating.rating * 100 }}%
          .rating-pro-modal__block-position
            .rating-pro-modal__position-title Позиция
            .rating-pro-modal__value  {{ rating.position }} из {{ rating.totalPositions }}
</template>

<script>
import { mapGetters } from 'vuex';
import { USER_PRO_RATING } from '@/store/actions/user';
import DialogStatisticsUnloading from "@/components/AwesomeComponents/Account/Profile/Dialogs/DialogStatisticsUnloading.vue";

export default {
  name: 'ProfileTurnover',
  components: { DialogStatisticsUnloading },
  data() {
    return {
      isRatingProModalOpened: false,
      proRating: [],
    };
  },
  computed: {
    ...mapGetters({
      profile: 'getProfile',
      loading: 'loading',
    }),
    isProRatingLoading() {
      return this.loading(USER_PRO_RATING) === 'loading';
    },
    proRatingSortedByPriority() {
      return [...this.proRating].sort((a, b) => b.payment_system.is_bank - a.payment_system.is_bank);
    },
    proRatingTop3() {
      return this.proRatingSortedByPriority.slice(0, 3);
    },
  },
  created() {
    if (this.profile.pro_trader) {
      this.loadRatingPro();
    }
  },
  methods: {
    async loadRatingPro() {
      this.proRating = await this.$store.dispatch(USER_PRO_RATING);
    },
  },
};
</script>

<style scoped lang="scss">
.rating-pro-modal {
  &__title {
    .awesome-tag {
      margin-left: 8px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 24px;

    margin-top: 17px;

    &__item {
      display: flex;
      justify-content: space-between;
      margin-right: 40px;
    }
  }

  &__rating-title {
    color: $blue600;

    /* S/Medium 14 */
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 128.571% */
  }

  &__position-title {
    color: $gray600;

    /* S/Medium 14 */
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px; /* 128.571% */
  }

  &__value {
    color: $gray800;

    /* L/Medium 18 */
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 133.333% */
  }
}
</style>