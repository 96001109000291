const INPUT_DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss ZZ';

const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;

const CONFIG = {
  LAST_TIME_FOR_ATTENTION: 3 * DAY,
  DEFAULT_DATE_FORMAT: 'DD.MM.YYYY в HH:mm',
};

export default {
  data() {
    return {
      // implement data or prop "specialCommissionsPeriod" that is { from: string | Date, to: string | Date }
      timeLeft: null,
    };
  },
  computed: {
    specialOn() {
      return this.timeLeft > 0;
    },
    timeLeftHumanized() {
      if (!this.timeLeft) {
        return;
      }

      /** @url https://momentjs.com/docs/#/customization/relative-time-threshold/ */
      const params = {
        'ss': 0,
        's': 61,
        'm': 60,
        'h': 48, // hours for last two days
        'd': 62, // days for last two months
        'w': null,
        'M': 24, // months for last two years
      };

      /** @url https://momentjs.com/docs/#/customization/relative-time-rounding/ */
      const defaultRounding = this.$moment.relativeTimeRounding();

      if (this.timeLeft / MINUTE > 1) {
        this.$moment.relativeTimeRounding(Math.floor);
      }

      const result = this.timeLeft.humanize(true, params);

      this.$moment.relativeTimeRounding(defaultRounding);

      return result;
    },
    soonEnding() {
      return Boolean(this.timeLeft && this.timeLeft <= CONFIG.LAST_TIME_FOR_ATTENTION);
    },
  },
  watch: {
    specialCommissionsPeriod(value) {
      if (value.from && value.to) {
        this.timeLeft = this.getDuration();
      }
    },
    timeLeft: {
      immediate: true,
      handler(value) {
        if (value === null) {
          this.timeLeft = this.getDuration();
          return;
        }

        if (!this.specialOn) {
          return;
        }

        const duration = value;
        let interval;

        if (duration.asMinutes() - SECOND/MINUTE < 1) {
          interval = this.timeLeft % SECOND;
        } else if (duration.asHours() - MINUTE/HOUR < 1) {
          interval = this.timeLeft % MINUTE;
        } else if (duration.asDays() - HOUR/DAY < 1) {
          interval = this.timeLeft % HOUR;
        } else {
          interval = this.timeLeft % DAY;
        }

        setTimeout(() => {
          this.timeLeft = this.getDuration();
        }, interval);
      },
    },
  },
  methods: {
    getDuration() {
      const now = this.$moment();
      const endTime = this.$moment(this.specialCommissionsPeriod.to, INPUT_DATE_FORMAT);
      return this.$moment.duration(endTime.diff(now));
    },
    formatDate(date, format = CONFIG.DEFAULT_DATE_FORMAT) {
      return this.$moment(date, INPUT_DATE_FORMAT).format(format);
    },
  },
};
