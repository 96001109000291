<template lang='pug'>
  .dialog-profile-avatar-uploaded__content
    .dialog-profile-avatar__subheader
      span Аватар будет заменен на изображение по умолчанию
    .dialog-profile-avatars__container
      .dialog-profile-avatar-block
        img(:src='avatar' alt='avatar')
      .dialog-profile-avatar-separator
        img(src='/awesome-icons/arrow-right.svg')
      .dialog-profile-avatar-default-block
        img(src='/img/default-avatar.png' alt='default-avatar')
</template>

<script>
import { AVATAR_DELETE } from '@/store/actions/user';

export default {
  name: 'UploadedProfileAvatar',
  props: {
    avatar: {
      type: String,
      default: '',
    },
    toggleRequest: {
      type: String,
      required: true,
    },
  },
  watch: {
    toggleRequest: {
      handler(newVal) {
        if (newVal === 'uploaded') {
          this.uploadedProfileImage();
        }
      },
    },
  },
  methods: {
    uploadedProfileImage() {
      this.$confirm(
        'Вы уверены что хотите удалить аватар',
        'Подтверждение',
      ).then(() => {
        this.$store.dispatch(AVATAR_DELETE);
        this.$emit('close');
      }).catch(() => {
        this.$message.error('Не удалось удалить аватар');
        this.$emit('close');
      });
    },
  },
};
</script>

<style scoped lang='scss'>
.dialog-profile-avatar-uploaded__content {
  .dialog-profile-avatar {
    &__subheader {
      margin-top: 12px;

      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: $gray600;
      }
    }
  }

  .dialog-profile-avatars {
    &__container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 27px;
      gap: 16px;

      .dialog-profile-avatar {
        &-block {
          width: 80px;
          height: 80px;

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }

        &-default-block {
          width: 80px;
          height: 80px;

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
      }
    }
  }
}
</style>