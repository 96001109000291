<template lang='pug'>
div(v-if='!data.length')
  .awesome-profile-reviews-empty
    .awesome-profile-reviews-empty__icon
    .awesome-profile-reviews-empty__text
      span У вас пока что нет отзывов
div(v-else)
  .awesome-profile-reviews-cards-container
    .awesome-profile-review-card(
      v-for='review in data'
      :key='review.id'
    )
      .awesome-profile-review-card__title
        span {{ review.from_user.login }}
      .awesome-profile-review-card__content
        .review-content
          .review-content-desc
            span {{ review.message }}
          .review-content-bottom
            span.review-content-bottom__time {{ divideDate(review.created_at).date }} {{ divideDate(review.created_at).time }}
            span.review-emoji(:class='review.trust')
  .awesome-profile-review-pagination
    el-pagination(
      background,
      v-if='pagination.total_pages > 1',
      :total='pagination.total',
      :page-size='parseInt(pagination.per_page)',
      :current-page='pagination.current_page',
      layout='prev, pager, next',
      @current-change='changeCurrentPage',
    )
</template>

<script>
import { mapGetters } from 'vuex';
import { AD_USER_REVIEWS } from '@/store/actions/ad';
import cfg from '../../../../../../config';
import utilsMixin from '@/components/mixins/utils';

export default {
  name: 'ProfileReviewsList',
  mixins: [utilsMixin],
  props: {
    typeReviews: {
      type: String,
      default: '',
      required: true,
    }
  },
  data() {
    return {
      pagination: {
        current_page: 1,
      },
      data: [],
    }
  },
  computed: {
    ...mapGetters({
      'profile': 'getProfile',
      'reviews': 'getReviews',
    }),
  },
  mounted() {
    this.getData(this.pagination.current_page);
  },
  methods: {
    changeCurrentPage(page) {
      this.getData(page);
    },
    getData(page) {
      let filter = {
        trust: this.typeReviews,
        per_page: parseInt(cfg.per_page_min),
        page: page
      };
      if (this.typeReviews === 'all') {
        filter = { per_page: parseInt(cfg.per_page_min), page: page };
      }
      this.$store.dispatch(AD_USER_REVIEWS, { userId: this.profile.id, filter }).then(() => {
        this.pagination = this.reviews.pagination;
        this.data = this.reviews.data;
        this.$emit('contentDownloaded', this.pagination.total);
      });
    },
  }
};
</script>

<style scoped lang='scss'>

</style>