<template lang='pug'>
.awesome-profile-card
  .awesome-profile-card__title
    span Статистика
  .awesome-profile-card__content
    .content-statistics
      .deal-statistics
        .content-subheader
          span Сделок
        .content-desc
          span {{ userProfileDetails.statistics.deals_count }}
      .transaction-turnover-statistics
        .content-subheader
          span Оборот
        .content-desc
          span {{ turnoverInfo.btc.turnover + ' ' + turnoverInfo.btc.code }}
  .awesome-profile-card__footer
    button.awesome-btn.awesome-btn-outline(@click="handleOpenDialog")
      span Выгрузить данные
  template(v-if="visibleDialog")
    DialogStatisticsUnloading(:opened="visibleDialog" @close="handleCloseDialog")
</template>

<script>
import { mapGetters } from 'vuex';
import DialogStatisticsUnloading from "@/components/AwesomeComponents/Account/Profile/Dialogs/DialogStatisticsUnloading.vue";

export default {
  name: 'ProfileTurnover',
  components: { DialogStatisticsUnloading },
  data() {
    return {
      visibleDialog: false,
    }
  },
  computed: {
    ...mapGetters({
      'profile': 'getProfile',
      'statisticTurnover': 'getTurnover',
      'userProfileDetails': 'getDetails',
    }),
    turnoverInfo() {
      let crypto = {
        btc: {
          turnover: ''
        }
      };
      if (this.statisticTurnover.data) {
        this.statisticTurnover.data.forEach((current) => {
          crypto[current.code] = {
            turnover: current.turnover,
            code: current.code.toUpperCase(),
          }
        });
      }
      return crypto;
    }
  },
  methods: {
    handleOpenDialog() {
      this.visibleDialog = true;
    },
    handleCloseDialog() {
      this.visibleDialog = false;
    }
  }
};
</script>

<style scoped lang='scss'>
</style>