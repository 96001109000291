<template lang='pug'>
  main.main-content.layout-main__centering.container.pt-5(
    v-loading='isLoading',
    element-loading-text='Загрузка данных...',
  )
    AwesomeTabs(:tabs='tabs' :selected-tab='selectedTab' @changeTab='changeTab')
      template(v-if='selectedTab === "AwesomeProfile"')
        .awesome-profile-cards-container
          Profile(:total-reviews='totalReviews')
          ProfileTurnover
          ProfileCommissions
        ProfileProRating(v-if="profile && profile.pro_trader")
        .awesome-profile-reviews-container
          ProfileReviews(@totalReviews='getTotalReviews')
      template(v-if='selectedTab === "AwesomeProfileSecurity"')
        ProfileSecurity
      template(v-if='selectedTab === "AwesomeProfileNotifications"')
        ProfileNotifications
      template(v-if='selectedTab === "AwesomeProfileLogs"')
        ProfileLogs
</template>

<script>
import { mapGetters } from 'vuex';
import AwesomeTabs from '@/components/AwesomeComponents/Tabs.vue';
import Profile from '@/components/AwesomeComponents/Account/Profile/Profile.vue';
import ProfileSecurity from '@/components/AwesomeComponents/Account/Profile/Security/ProfileSecurity.vue';
import ProfileNotifications
  from '@/components/AwesomeComponents/Account/Profile/Notifications/ProfileNotifications.vue';
import ProfileLogs from '@/components/AwesomeComponents/Account/Profile/Logs/ProfileLogs.vue';
import ProfileTurnover from '@/components/AwesomeComponents/Account/Profile/ProfileTurnover.vue';
import ProfileProRating from '@/components/AwesomeComponents/Account/Profile/ProfileProRating.vue';
import ProfileCommissions from '@/components/AwesomeComponents/Account/Profile/ProfileCommissions.vue';
import ProfileReviews from '@/components/AwesomeComponents/Account/Profile/Reviews/ProfileReviews.vue';
import { CLIENT_DETAILS } from '@/store/actions/client';
import { AD_USER_REVIEWS } from '@/store/actions/ad';
import {SECURITY_REQUEST} from "../../store/actions/security";

export default {
  components: {
    ProfileReviews,
    ProfileCommissions,
    ProfileTurnover,
    ProfileProRating,
    ProfileLogs,
    ProfileNotifications,
    ProfileSecurity,
    Profile,
    AwesomeTabs,
  },
  data() {
    return {
      tabs: [
        { name: 'AwesomeProfile', label: 'Профиль' },
        { name: 'AwesomeProfileSecurity', label: 'Безопасность' },
        { name: 'AwesomeProfileNotifications', label: 'Уведомления' },
        { name: 'AwesomeProfileLogs', label: 'Журнал действий' },
      ],
      selectedTab: 'AwesomeProfile',
      totalReviews: 0,
    };
  },
  computed: {
    ...mapGetters({
      'profile': 'getProfile',
      'loading': 'loading',
    }),
    isLoading() {
      return this.loading(CLIENT_DETAILS) === 'loading' || this.loading(AD_USER_REVIEWS) === 'loading';
    },
  },
  mounted() {
    this.selectedTab = this.$route.name;
    this.$store.dispatch(CLIENT_DETAILS, { id: this.profile.id });
    this.$store.dispatch(SECURITY_REQUEST);
  },
  methods: {
    changeTab(tabName) {
      this.selectedTab = tabName;
      this.$router.push({ name: tabName });
    },
    getTotalReviews(int) {
      this.totalReviews = int;
    },
  },
};
</script>

<style lang='scss' scoped>
.main-content {
  padding-bottom: 40px;
}
</style>
