<template lang='pug'>
  el-dialog.dialog-profile-avatar(
    :title='isDialogTitle'
    :visible.sync='dialogVisible'
    width='370px'
    class='awesome-el-dialog'
    @close='handleCloseDialog'
  )
    template(v-if='isEdit')
      UploadProfileAvatar(:toggle-request='toggleRequest' @close='handleCloseDialog')
    template(v-else)
      UploadedProfileAvatar(:avatar='avatar()' :toggle-request='toggleRequest' @close='handleCloseDialog')
    .dialog-profile-avatar__footer(slot='footer')
      button.awesome-btn.awesome-btn-outline(@click='handleCancel')
        span Отменить
      button.awesome-btn(@click='requestAvatar')
        span {{ isBtnText }}
</template>

<script>
import { mapGetters } from 'vuex';
import UploadProfileAvatar
  from '@/components/AwesomeComponents/Account/Profile/Dialogs/Components/UploadProfileAvatar.vue';
import UploadedProfileAvatar
  from '@/components/AwesomeComponents/Account/Profile/Dialogs/Components/UploadedProfileAvatar.vue';

export default {
  name: 'DialogProfileAvatar',
  components: {
    UploadedProfileAvatar,
    UploadProfileAvatar,
  },
  props: {
    opened: {
      type: Boolean,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      dialogVisible: false,
      toggleRequest: '',
    };
  },
  computed: {
    ...mapGetters({
      'avatar': 'getAvatar',
    }),
    isDialogTitle() {
      return this.isEdit ? 'Редактирование аватара' : 'Удаление аватара';
    },
    isBtnText() {
      return this.isEdit ? 'Сохранить' : 'Удалить';
    },
  },
  mounted() {
    this.dialogVisible = this.opened;
  },
  methods: {
    handleCloseDialog() {
      this.$emit('close');
    },
    handleCancel() {
      this.handleCloseDialog();
    },
    requestAvatar() {
      if (this.isEdit) {
        this.toggleRequest = 'upload';
      } else {
        this.toggleRequest = 'uploaded';
      }
    },
  },
};
</script>

<style scoped lang='scss'>
.dialog-profile-avatar {
  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 22px;
    gap: 16px;
  }
}
</style>