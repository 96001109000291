<template lang='pug'>
  .awesome-profile-logs__container(
    v-loading='isLoading',
    element-loading-text='Загрузка данных...',
  )
    AwesomeTabs(:tabs='tabs' :selected-tab='selectedTab' :is-data-visible='true' @changeTab='changeTab')
      div(v-show='selectedTab === "auth"')
        ProfileAuthorizationLogs(:headers='headers' @contentDownloaded='setAuthorizationLogQuantity')
      div(v-show='selectedTab === "security"')
        ProfileSecurityLogs(:headers='headers' @contentDownloaded='setSecurityLogQuantity')
</template>

<script>
import { mapGetters } from 'vuex';
import { USER_SECURITY_LOGS, USER_SESSIONS_LIST } from '@/store/actions/user';
import AwesomeTabs from '@/components/AwesomeComponents/Tabs.vue';
import ProfileAuthorizationLogs
  from '@/components/AwesomeComponents/Account/Profile/Logs/Tables/ProfileAuthorizationLogs.vue';
import ProfileSecurityLogs from '@/components/AwesomeComponents/Account/Profile/Logs/Tables/ProfileSecurityLogs.vue';

export default {
  name: 'ProfileLogs',
  components: {
    ProfileSecurityLogs,
    ProfileAuthorizationLogs,
    AwesomeTabs,
  },
  data() {
    return {
      tabs: [
        { name: 'auth', label: 'Авторизация', data: 0 },
        { name: 'security', label: 'Безопасность', data: 0 },
      ],
      selectedTab: 'auth',
      securityLogQuantity: 0,
      authorizationLogQuantity: 0,
      headers: [
        { label: 'Событие', value: 'type' },
        { label: 'Дата и время', value: 'time' },
        { label: 'IP адрес', value: 'ip' },
        { label: 'Статус', value: 'status' },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'loading',
    ]),
    isLoading() {
      return this.loading(USER_SESSIONS_LIST) === 'loading' || this.loading(USER_SECURITY_LOGS) === 'loading';
    },
  },
  methods: {
    changeTab(tabName) {
      this.selectedTab = tabName;
    },
    setSecurityLogQuantity(quantity) {
      this.tabs[1].data = quantity;
    },
    setAuthorizationLogQuantity(quantity) {
      this.tabs[0].data = quantity;
    },
  },
};
</script>

<style lang='scss'>
.el-pagination {
  button,
  .btn-prev,
  .btn-next,
  li,
  li.number {
    background: none !important;
  }

  button:disabled {
    color: $gray200;
    cursor: not-allowed !important;

    &:hover {
      color: $gray200;
    }
  }

  button:hover {
    color: $blue600;
  }

  .btn-next, .btn-prev {
    color: $gray600;
  }

  .el-pager {
    li {
      font-size: 14px;
    }

    li.btn-quicknext {
      color: $gray600;
    }

    li:hover {
      color: $blue600;
    }
  }
}

.awesome-profile-logs__container {
  background: $blue50;
  border-radius: 16px;
  padding: 16px;
  margin-bottom: 20px;

  .awesome-profile-logs__table {
    margin-top: 16px;
    border-collapse: collapse;
    width: 100%;

    thead {
      border-bottom: 1px solid $blue200;

      tr {

        th {
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          color: $gray600;
          padding: 11px 12px;
        }
      }
    }

    tbody {
      .table-cell {

        td {
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: $gray800;
          padding: 15px 12px;
        }

        .icon-status {
          margin-left: 15px;
        }

        &:hover {
          background-color: $blue100;

          td:first-child {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
          }

          td:last-child {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
          }
        }
      }
    }
  }

  .awesome-profile-logs__pagination {
    margin-top: 24px
  }
}
</style>
