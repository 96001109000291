<template lang='pug'>
  .awesome-profile-logs
    table.awesome-profile-logs__table
      thead
        tr
          th(v-for='header in headers' :key='header.value') {{ header.label }}
      tbody
        tr.table-cell(v-for='item in tableData' :key='item.id')
          td(v-for='header in headers' :key='header.value')
            span(v-if='header.value === "time"') {{ divideDate(item.time).date }} {{ divideDate(item.time).time }}
            span.icon-status(v-else-if='header.value === "status"')
              img(v-if='item.status === "good"' src='/awesome-icons/check-success.svg')
              img(v-else src='/awesome-icons/check-failure.svg')
            span(v-else) {{ item[header.value] }}
    .awesome-profile-logs__pagination
      el-pagination(
        v-if='pagination.total_pages > 1',
        :total='pagination.total',
        :page-size='parseInt(pagination.per_page)',
        :current-page='pagination.current_page',
        layout='prev, pager, next',
        @current-change='changeCurrentPage',
      )
</template>

<script>
import { USER_SESSIONS_LIST } from '@/store/actions/user';
import { mapGetters } from 'vuex';
import utilsMixin from '@/components/mixins/utils';

export default {
  name: 'ProfileAuthorizationLogs',
  mixins: [utilsMixin],
  props: {
    headers: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      pagination: {
        page: 1,
      },
      tableData: [],
    };
  },
  computed: {
    ...mapGetters([
      'gerUserSessionsList',
    ]),
  },
  created() {
    this.getData(this.pagination.page);
  },
  methods: {
    changeCurrentPage(page) {
      this.getData(page);
    },
    getData(page) {
      this.$store.dispatch(USER_SESSIONS_LIST, { filter: { page } }).then(() => {
        this.tableData = this.gerUserSessionsList.data;
        this.pagination = this.gerUserSessionsList.pagination;
        this.$emit('contentDownloaded', this.pagination.total);
      });
    },
  },
};
</script>

<style lang='scss'>
</style>