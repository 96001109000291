<template lang='pug'>
  .dialog-profile-avatar-upload__content
    .dialog-profile-avatar__subheader(v-if='!isHaveImage')
      span Формат: png, jpg, jpeg
      br
      span Максимальный размер: 2 МБ
    .dialog-profile-avatar__drop-zone(
      @drop.prevent='handleDrop'
      @dragover.prevent
      v-if='!isHaveImage'
    )
      .dialog-profile-avatar__drop-zone__title
        span Загрузите или перетащите сюда фото
      .dialog-profile-avatar__drop-zone__btn(@click='handleChooseFile')
        button.awesome-btn
          span Загрузить фото
    .dialog-profile-avatar__container(v-show='isHaveImage')
      .dialog-profile-avatar__container-block
        croppa.avatar-block(
          v-model='cropped',
          :disable-scroll-to-zoom='config.disableScrollToZoom',
          :zoom-speed='config.zoomSpeed',
          :prevent-white-space='config.preventWhiteSpace',
          :file-size-limit='config.fileSizeLimit',
          :width='config.width',
          :height='config.height',
          :show-remove-button='config.showRemoveButton',
          :loading-size='config.loadingSize',
          :show-loading='config.showLoading',
          :accept='config.accept',
          :quality='config.quality',
          :replace-drop='config.replaceDrop',
          :initial-size='config.initialSize',
          :canvas-color='config.canvasColor',
          :loading-color='config.loadingColor',
          @file-type-mismatch='handleCroppaError',
          @file-size-exceed='handleFileSizeExceeded',
          @init='initClipPlugin',
        )
          img(slot='initial' :src='imageSrc')
        .slider-block
          el-slider(v-model.lazy='fileZoom', @change='handleChangeZoom', :show-tooltip='false')
</template>

<script>
import croppa from 'vue-croppa';
import { AVATAR_UPDATE } from '@/store/actions/user';
import cfg from "../../../../../../../config";

export default {
  name: 'UploadProfileAvatar',
  components: {
    croppa: croppa.component,
  },
  props: {
    toggleRequest: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      imageSrc: null,
      cropped: null,
      type: null,
      fileZoom: 50,
      oldFileZoom: 50,
      config: {
        disableScrollToZoom: true,
        zoomSpeed: 3,
        preventWhiteSpace: true,
        fileSizeLimit: parseInt(cfg.message_max_filesize),
        width: 120,
        height: 120,
        showRemoveButton: false,
        loadingSize: 140,
        showLoading: true,
        accept: '.jpg,.jpeg,.png',
        quality: 2,
        replaceDrop: true,
        initialSize: 'contain',
        canvasColor: 'transparent',
        loadingColor: '#2d67de',
      },
    };
  },
  computed: {
    isHaveImage() {
      return this.cropped && this.cropped.img;
    },
  },
  watch: {
    toggleRequest: {
      handler(newVal) {
        if (newVal === 'upload') {
          this.uploadCroppedImage();
        }
      },
    },
  },
  methods: {
    handleDrop(event) {
      const file = event.dataTransfer.files[0];
      const types = [
        'image/jpeg',
        'image/jpg',
        'image/png',
      ];
      if (!types.includes(file.type)) {
        this.$message.error(`Ошибка, ${file.name} не является форматом jpg/jpeg/png`);
        return;
      } else if (file.size > this.isMaxFileSize) {
        this.$message.error(`Превышен размер файла "${file.name}": максимальный размер - 2 МБ`);
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        this.imageSrc = reader.result;
      };
      reader.readAsDataURL(file);
    },
    handleChooseFile() {
      this.cropped.chooseFile();
    },
    handleChangeZoom(val) {
      let zoom = Math.abs(val - this.oldFileZoom);
      if (this.oldFileZoom > val) {
        zoom -= 1;
      }
      for (let i = 0; i < zoom; i++) {
        if (this.oldFileZoom < val) {
          this.cropped.zoomIn();
        } else if (this.oldFileZoom > val) {
          this.cropped.zoomOut();
        }
      }
      this.oldFileZoom = val;
    },
    uploadCroppedImage() {
      this.cropped.generateBlob((blob) => {
        const formData = new FormData();
        formData.append('image', blob, `image.${blob.type.split('/', 2)[1]}`);
        this.sendCroppedImage(formData);
      }, 'image/png');
    },
    sendCroppedImage(formData) {
      this.$store.dispatch(AVATAR_UPDATE, {
        img: formData,
      }).then(() => {
        this.$emit('close');
      }).catch(() => {
        this.$emit('close');
        this.$message.error('Не удалось загрузить аватар');
      });
    },
    handleCroppaError(file) {
      this.$message.error(`Ошибка, ${file.name} не является форматом jpg/jpeg/png`);
    },
    handleFileSizeExceeded(file) {
      this.$message.error(`Превышен размер файла "${file.name}": максимальный размер - 2 МБ`);
    },
    initClipPlugin() {
      this.cropped.addClipPlugin(function (ctx, x, y, w, h) {
        ctx.beginPath();
        ctx.arc(x + w / 2, y + h / 2, w / 2, 0, 2 * Math.PI, true);
        ctx.closePath();
      });
    },
  },
};
</script>

<style scoped lang='scss'>
.dialog-profile-avatar-upload__content {
  .dialog-profile-avatar {
    &__subheader {
      margin-top: 12px;

      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: $gray600;
      }
    }

    &__drop-zone {
      display: flex;
      flex-direction: column;
      border: 1px dashed $blue300;
      border-radius: 16px;
      margin-top: 23px;
      padding: 18px 16px 16px;
      gap: 28px;

      &__title {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: $gray800;
      }

      &__btn {
        display: flex;
        justify-content: flex-end;
      }
    }

    &__container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 19px;

      &-block {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background: #EDF4FF;
        border-radius: 10px;
        padding: 16px;
        width: 240px;

        .avatar-block {
          border-radius: 50%;
          overflow: hidden;
          cursor: pointer;
          max-width: 120px;
          max-height: 120px;
        }

        .slider-block {
          margin-top: 12px;
          width: 100%
        }
      }
    }
  }
}
</style>