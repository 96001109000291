<template lang='pug'>
  el-dialog.dialog-statistics-unloading.awesome-el-dialog(
    title='Выгрузка статистики'
    width='370px'
    class='awesome-el-dialog'
    :visible.sync='dialogVisible'
    @close='handleCloseDialog'
  )
    .dialog-statistics-unloading__content
      .statistics-subtitle
        span
          | {{ `При выгрузке статистики формируется отчет за период. Файл с отчетом будет отправлен на почту ${ profile.email }.` }}
      .statistics-tabs
        AwesomeTabs(
          :tabs='tabs'
          :selected-tab='selectedTab'
          custom-class-nav-item='dialog-statistics-tabs-nav__item'
          custom-class-nav='dialog-statistics-tabs-nav'
          @changeTab='changeTab'
        )
          .statistics-tabs__content
            el-date-picker(
              v-model='date'
              type='daterange',
              range-separator='—',
              start-placeholder='Начало периода',
              end-placeholder='Конец периода',
              format='dd-MM-yyyy',
              value-format='dd/MM/yyyy',
              :clearable='false',
              :picker-options='{ disabledDate }',
            )
    .dialog-statistics-unloading__footer(slot='footer')
      button.awesome-btn.awesome-btn-outline(@click='handleCancel')
        span Отменить
      button.awesome-btn(@click='requestStatisticsUploading' :disabled='isDisabledBtn')
        span Выгрузить
</template>

<script>
import AwesomeTabs from "@/components/AwesomeComponents/Tabs.vue";
import { LOAD_DEAL, LOAD_SUMMARY } from '@/store/actions/user';
import { mapGetters } from 'vuex';
import utils from "@/components/mixins/utils";

export default {
  name: 'DialogStatisticsUnloading',
  components: {
    AwesomeTabs,
  },
  mixins: [utils],
  props: {
    opened: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      dialogVisible: false,
      tabs: [
        { name: 'statistics', label: 'Статистика' },
        { name: 'deals', label: 'Сделки' },
      ],
      selectedTab: 'statistics',
      date: [],
    };
  },
  computed: {
    ...mapGetters({
      'profile': 'getProfile',
    }),
    isDisabledBtn() {
      return !this.date.length;
    },
  },
  watch: {
    selectedTab: {
      handler() {
        this.date = [];
      },
    },
  },
  mounted() {
    this.dialogVisible = this.opened;
  },
  methods: {
    handleCloseDialog() {
      this.$emit('close');
    },
    handleCancel() {
      this.handleCloseDialog();
    },
    changeTab(tabName) {
      this.selectedTab = tabName;
    },
    requestStatisticsUploading() {
      if (this.selectedTab === 'statistics') {
        this.$store.dispatch(LOAD_SUMMARY, this.date).then(() => {
          this.$message({
            message: 'Запрос на формирования отчета успешно отправлен',
            type: 'success',
            showClose: true,
          });
        }).catch(() => {
          this.$message({
            message: 'Не удалось отправить запрос. Проверьте правильность введенных данных',
            type: 'error',
            showClose: true,
          });
        });
      } else {
        this.$store.dispatch(LOAD_DEAL, this.date).then(() => {
          this.$message({
            message: 'Запрос на формирования отчета успешно отправлен',
            type: 'success',
            showClose: true,
          });
        }).catch(() => {
          this.$message({
            message: 'Не удалось отправить запрос. Проверьте правильность введенных данных',
            type: 'error',
            showClose: true,
          });
        });
      }
      this.date = [];
      this.handleCloseDialog();
    },
  },
};
</script>

<style lang='scss'>
.dialog-statistics-unloading {
  &__content {
    margin-top: 11px;

    .statistics-subtitle {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: $gray600;
    }

    .statistics-tabs {
      margin-top: 12px;

      .dialog-statistics-tabs-nav {
        max-width: 100% !important;

        &__item {
          flex: 1;
          text-align: center;
        }
      }

      &__content {
        margin-top: 22px;
      }
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 22px;
    gap: 16px;
  }
}
</style>