<template lang='pug'>
  .awesome-profile-notifications__content
    .awesome-profile-notifications__content-title
      span {{ notificationsChannel.label }}
    .awesome-profile-notifications__content-desc
      span {{ notificationsChannel.description }}
    .awesome-profile-notifications__content-notification
      .awesome-profile-notifications__content-notification__container(
        :class="{ 'awesome-profile-notifications__content-notification__container_disabled': isLoading }"
        @click='toggleInput(notificationsChannel.type)'
      )
        .profile-notifications__content-notification__type
          span Включить {{ notificationsChannel.label }} уведомления
        label.awesome-switch
          input.awesome-checkbox(
            type='checkbox'
            :disabled='isLoading'
            :checked='notificationsChannel.value'
          )
          span.awesome-slider
      .awesome-profile-notifications__content-notification__types-list
        template(
          v-for='type in notificationsChannel.types'
          :class="{ 'awesome-profile-notifications__content-notification__container_disabled': isLoading }"
        )
          .awesome-profile-notifications__content-notification__container(
            v-if="notificationsChannel.value || type.alwaysEnabled"
            :key='type.type'
            @click='togglePosition(notificationsChannel.type, type)'
          )
            .profile-notifications__content-notification__type
              span {{ type.label }}
            label.awesome-switch
              input.awesome-checkbox(
                type='checkbox'
                :checked='type.alwaysEnabled || type.value'
                :disabled='isPositionDisabled(type)'
              )
              span.awesome-slider
</template>

<script>

import { NOTIFICATION_GROUP_REQUEST, NOTIFICATION_POSITION_REQUEST, SETTINGS_REQUEST } from '@/store/actions/settings';
import { mapGetters } from 'vuex';

export default {
  name: 'Notifications',
  props: {
    notificationsChannel: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      'loading': 'loading',
    }),
    isLoading() {
      return this.loading(SETTINGS_REQUEST) === 'loading'
        || this.loading(NOTIFICATION_POSITION_REQUEST) === 'loading'
        || this.loading(NOTIFICATION_GROUP_REQUEST) === 'loading';
    },
  },
  created() {
    this.setNotificationSettings();
  },
  methods: {
    setNotificationSettings() {
      this.$store.dispatch(SETTINGS_REQUEST).then(() => {
        this.$emit('togglePosition');
      });
    },
    toggleInput(type) {
      if (this.isLoading) {
        return;
      }

      const channel = this.notificationsChannel;
      if (type && channel) {
        channel.types.forEach(i => {
          i.value = !channel.value;
        });
        this.$store.dispatch(NOTIFICATION_GROUP_REQUEST, { parameter: `${!channel.value ? 'enable' : 'disable'}-${type}-notifications` }).then(() => {
          this.setNotificationSettings();
        });
      }
    },
    togglePosition(group, channelType) {
      if (this.isPositionDisabled(channelType)) {
        return;
      }

      const channelTypeAlias = channelType.requestAlias || channelType.type;
      this.$store.dispatch(NOTIFICATION_POSITION_REQUEST, { parameter: `${!channelType.value ? 'enable' : 'disable'}-${group}-${channelTypeAlias}-notifications` }).then(() => {
        this.$emit('togglePosition', group, channelType);
        this.setNotificationSettings();
      });
    },
    isPositionDisabled(channelType) {
      return this.isLoading || channelType.alwaysEnabled;
    },
  },
};
</script>

<style scoped lang='scss'>
</style>