<template lang='pug'>
.awesome-profile-card
  .awesome-profile-card__title.awesome-profile-card__title_with-right-addition
    span Комиссии
    .awesome-profile-card__title__right-addition(v-if="isSpecialCommissionsAcitve")
      | {{ lastDateInCardTitle }}
  .awesome-profile-card__content
    .content-commissions
      .taker-commissions
        .content-subheader
          span Оффер
          el-tooltip(effect='dark' placement='bottom')
            img.icon(src="/awesome-icons/info-mini.svg")
            div(slot="content")
              | Оффер — автор объявления
        .content-desc
          span {{ commissions.offer + '%' }}
      .maker-commissions(effect='dark' placement='bottom')
        .content-subheader
          span Клиент
          el-tooltip(effect='dark' placement='bottom')
            img.icon(src="/awesome-icons/info-mini.svg")
            div(slot="content")
              | Клиент — тот, кто обращается по объявлению и открывает сделку
        .content-desc
          span {{ commissions.client + '%' }}
  .awesome-profile-card__footer.awesome-profile-card__footer_both-sides
    .special-commissions__container
      el-tooltip(v-if="isSpecialCommissionsAcitve" effect='dark' placement='bottom')
        div.special-commission-alert
          img.icon(src="/awesome-icons/warning-blue-mini.svg")
          | Действуют спец. комиссии
        div(slot="content")
          b Действуют специальные комиссии
          br
          | Закончатся {{ this.timeLeftHumanized }}
          br
          br
          | Начались {{ this.formatDate(this.specialCommissionsPeriod.from) }};
          br
          | закончатся {{ this.formatDate(this.specialCommissionsPeriod.to) }}
    button.awesome-btn.awesome-btn-outline(@click='handleOpenMore')
      span Подробнее
</template>

<script>
import { mapGetters } from 'vuex';
import specialCommissionsTimer from '@/components/mixins/specialCommissionsTimer.js';

export default {
  name: 'ProfileCommissions',
  mixins: [
    specialCommissionsTimer,
  ],
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      'userCommissions': 'getUserCommissions',
    }),
    commissions() {
      return {
        offer: this.userCommissions.total_calculated.offer_commission.btc,
        client: this.userCommissions.total_calculated.client_commission.btc,
      };
    },
    specialCommissionsPeriod() {
      if (!this.userCommissions.special) {
        return {};
      }

      return {
        from: this.userCommissions.special.from,
        to: this.userCommissions.special.to,
      }
    },
    lastDateInCardTitle() {
      const FORMAT = 'DD.MM.YYYY HH:mm';
      return `до ${this.formatDate(this.specialCommissionsPeriod.to, FORMAT)}`;
    },
    isSpecialCommissionsAcitve() {
      return this.userCommissions.total_calculated.type === 'special';
    },
  },
  methods: {
    handleOpenMore() {
      this.$router.push({ name: 'Commissions' })
    }
  }
};
</script>

<style scoped lang='scss'>
</style>