<template lang='pug'>
  AwesomeTabs(:tabs='tabs' :selected-tab='selectedTab' :is-data-visible='true' @changeTab='changeTab')
    .reviews-tab-content(v-for='tab in tabs' :key='tab.name' :class='{ "active": tab.name === selectedTab }')
      ProfileReviewsList(:type-reviews='tab.name' @contentDownloaded='(value) => { setQuantityReviews(value, tab) }')
</template>

<script>
import AwesomeTabs from '@/components/AwesomeComponents/Tabs.vue'
import ProfileReviewsList from '@/components/AwesomeComponents/Account/Profile/Reviews/ProfileReviewsList.vue';

export default {
  name: 'ProfileReviews',
  components: {
    ProfileReviewsList,
    AwesomeTabs,
  },
  data() {
    return {
      tabs: [
        { name: 'all', label: 'Все отзывы', data: 0 },
        { name: 'positive', label: 'Положительные', data: 0 },
        { name: 'negative', label: 'Отрицательные', data: 0 },
        { name: 'neutral', label: 'Нейтральные', data: 0 },
      ],
      selectedTab: 'all',
    }
  },
  computed: {
  },
  methods: {
    changeTab(tabName) {
      this.selectedTab = tabName;
    },
    setQuantityReviews(quantity, tab) {
      this.$emit('totalReviews', this.tabs[0].data)
      this.tabs[this.tabs.indexOf(tab)].data = quantity;
    }
  }
};
</script>

<style scoped lang='scss'>
.reviews-tab-content {
  display: none;

  &.active {
    display: block;
  }
}
</style>